import styled from 'styled-components';
import Slider from "react-slick";
import { ContentSafeArea } from '../../components/globalComponents';

//#region HeroSection
export const HeroSection = styled.div`
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  /* border: 3px solid; */
`;

export const SliderSection = styled.div`
  width: 100%;
  height: 80vh;
  overflow: hidden;
  position: relative;

  @media(max-width: 600px) {
    height: 50vh;
  }
`;

export const SliderOverlay = styled.div`
  width: 100vw;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #FFFFFF 100%);
  /* background: red; */
  position: absolute;
  bottom: -1px;
  height: 30%;
  z-index: 2;
`;

export const HeroSlider = styled(Slider)`
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;

  div {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const InfoSection = styled.div`
  /* border: 1px solid; */
  width: 100%;
  min-height: 35%;
  position: absolute;
  top: 65%;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 600px) {
    position: relative;
  }

`;

export const IconsSection = styled(ContentSafeArea)`
  /* border: 2px solid; */
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;

  @media(max-width: 600px) {
    flex-direction: column;
  }
`;

export const IconImageContainer = styled.div`
  width: 5rem;
  margin-bottom: 1rem;
`

export const IconColumn = styled.div`
  min-height: 200px;
  flex: 1;
  margin: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid green; */
  text-align: center;
  /* background: red; */

  @media(max-width: 600px) {
    min-height: 0px;
  }

  img {
    width: 5rem;
  }

  h2 {
    font-weight: normal;
  }

`;

export const CallToActionSection = styled(ContentSafeArea)`
  /* border: 1px solid purple;  */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media(max-width: 600px) {
    padding-bottom: 1rem;
  }
`;
//#endregion

//#region Products

export const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media(min-width: 801px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const SmallProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: flex-start;

  @media(min-width: 801px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

//#endregion

//#region Clients

export const ClientsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const ClientSlider = styled(Slider)`
  width: 100%;
  margin: 0;
  margin-bottom: 3rem;
  padding: 0;

  div {
    max-height: 150px;
  }

  /* div {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  } */
`;

//#endregion
