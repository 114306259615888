import styled from 'styled-components';
import { StyledLink } from '../globalComponents';

export const SmallCardImageContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
    }
`;

export const SmallCardContainer = styled(StyledLink)`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #000;

    &:hover {
      color: #222;
      text-decoration: none;

      ${SmallCardImageContainer} {
        box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
        transition: box-shadow .1s ease-out .1s;
      }
    }


`;

export const CardContainer = styled(StyledLink)`
  border-radius: 15px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #000;

    &:hover {
      color: #000;
      text-decoration: none;
      background-color: #f7f7f7;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
      transition: background-color .1s ease-out .1s, box-shadow .1s ease-out .1s;
    }
`;

export const CardImageContainer = styled.div`
  width: 100%;
  /* padding-top: 100%; */
  border-radius: 15px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
`;

export const CardInfoContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0.5rem;
`;

export const CardTitle = styled.h2`
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
`;

export const SmallCardTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  margin-top: 0.3rem;
`;

export const CardInfo = styled.h3`
  font-weight: 300;
  margin: 0;
`;
