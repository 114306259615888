import React from 'react';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ProductCard from "../../components/productCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, ContentSafeArea, StyledLink } from '../../components/globalComponents';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PencilIcon from '../../images/svg/heroIcons/pencilIcon.svg';
import StopwatchIcon from '../../images/svg/heroIcons/stopWatchIcon.svg';
import TruckIcon from '../../images/svg/heroIcons/truckIcon.svg';

import {
  HeroSection,
  SliderSection,
  SliderOverlay,
  HeroSlider,
  InfoSection,
  IconsSection,
  IconColumn,
  IconImageContainer,
  CallToActionSection,
  ProductsContainer,
  SmallProductsContainer,
  ClientSlider
} from './styles';

export default function Home() {

  const {
    clientImages,
    featuredProductsMdx,
    sliderImages,
    productsMdx,
  } = useStaticQuery(graphql`
    query {
      clientImages: allFile(filter: {relativeDirectory: {eq: "clientes"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 150) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
            }
          }
        }
      }
      sliderImages: allFile(filter: {relativeDirectory: {eq: "slider"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 3000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
          }
        }
      }
      featuredProductsMdx: allMdx(filter: {frontmatter: {tags: {in: "Featured"}, draft: {ne: true}}}) {
        nodes {
          frontmatter {
            title
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      productsMdx: allMdx(filter: {frontmatter: {tags: {nin: "Featured"}, draft: {ne: true}}}) {
        nodes {
          frontmatter {
            title
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  const HeroSliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fade: true,
    pauseOnHover: false,
    cssEase: "linear"
  };

  const ClientSliderSettings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <Layout isHeaderFixed={true}>
      <SEO title="Home" />
      <HeroSection>
        <SliderSection>
          <SliderOverlay />
          <HeroSlider {...HeroSliderSettings}>
            {sliderImages.edges.map(({node}) =>
              <div>
                <Img
                  fluid={node.childImageSharp.fluid}
                />
              </div>
            )}
          </HeroSlider>
        </SliderSection>
        <InfoSection>
          <IconsSection>
            <IconColumn>
              <IconImageContainer>
                <StopwatchIcon alt='Produzimos em até 24h'/>
              </IconImageContainer>
              <h2>Produzimos em até <strong>24h</strong></h2>
            </IconColumn>
            <IconColumn>
              <IconImageContainer>
                <PencilIcon alt='Criação de artes grátis'/>
              </IconImageContainer>
              <h2>Criação de artes <strong>grátis</strong></h2>
            </IconColumn>
            <IconColumn>
              <IconImageContainer>
                <TruckIcon alt='Entrega ultra rápida'/>
              </IconImageContainer>
              <h2>Entrega <strong>ultra rápida</strong></h2>
            </IconColumn>
          </IconsSection>
          <CallToActionSection>
            <StyledLink to="/contato">
              <Button> Faça um orçamento </Button>
            </StyledLink>
          </CallToActionSection>
        </InfoSection>
      </HeroSection>
      <ContentSafeArea>
        {/* PRODUCT AREA */}
        <h1 style={{fontWeight: "normal"}}>Conheça nossos produtos:</h1>
        <ProductsContainer>
          {featuredProductsMdx.nodes.map(({frontmatter}) =>
            <ProductCard image={frontmatter.featuredImage.childImageSharp.fluid} title={frontmatter.title} slug={frontmatter.slug} price="R$00,00"/>
          )}
        </ProductsContainer>
        <SmallProductsContainer style={{marginTop: 25}} >
          {productsMdx.nodes.map(({frontmatter}) =>
            <ProductCard image={frontmatter.featuredImage.childImageSharp.fluid} title={frontmatter.title} slug={frontmatter.slug} isSmall={true}/>
          )}
        </SmallProductsContainer>
        <h1 style={{fontWeight: "normal", marginTop: 25}}>Nossos clientes:</h1>

        {/* CLIENTS AREA */}
        <ClientSlider {...ClientSliderSettings}>
          {clientImages.edges.map(({node}) =>
            <div>
              <Img
                fluid={node.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          )}
        </ClientSlider>

      </ContentSafeArea>
    </Layout>
  )

}
