import React from 'react';
import Img from 'gatsby-image';

import {
  SmallCardContainer,
  SmallCardImageContainer,
  CardContainer,
  CardImageContainer,
  CardInfoContainer,
  CardTitle,
  SmallCardTitle,
  // CardInfo,
} from './styles';

export default function ProductCard({image, title = "Card title", slug = "", price = "", isSmall = false}) {
  if(isSmall){
    return (
      <SmallCardContainer to={slug}>
        <SmallCardImageContainer>
          <CardImageContainer>
            <Img fluid={image} />
          </CardImageContainer>
        </SmallCardImageContainer>
        <SmallCardTitle>{title}</SmallCardTitle>
      </SmallCardContainer>
    )
  } else {
    return (
      <CardContainer to={slug}>
        <CardImageContainer>
          <Img fluid={image} />
        </CardImageContainer>
        <CardInfoContainer>
          <CardTitle>{title}</CardTitle>
          {/* <CardInfo>{price}</CardInfo> */}
        </CardInfoContainer>
      </CardContainer>
    )
  }
}
